/*
* Icons
* ================ */
.icon {
  display: inline-block;
  text-align: center; }

.icon__wrapper-main {
  max-width: 500px;
  margin: 0 auto; }
  @media (min-width: 992px) {
    .icon__wrapper-main {
      max-width: 100%; } }

.icon__wrapper * + p {
  margin-top: 25px; }

.icon__wrapper * + .btn {
  margin-top: 35px; }

.icon__wrapper svg {
  width: 70px;
  height: 70px; }

.icon__wrapper svg, .icon__wrapper .svg-wrap {
  margin-bottom: 27px; }

.icon__wrapper .livicon-evo + * {
  margin-top: 27px; }

.icon__wrapper-gradient {
  padding: 0 20px; }
  .icon__wrapper-gradient [class*="col"]:first-child > .icon__wrapper, .icon__wrapper-gradient [class*="col"]:first-child > .icon__wrapper > .after {
    border-top-left-radius: 30px;
    border-bottom-left-radius: 30px; }
    @media (max-width: 991px) {
      .icon__wrapper-gradient [class*="col"]:first-child > .icon__wrapper, .icon__wrapper-gradient [class*="col"]:first-child > .icon__wrapper > .after {
        border-bottom-left-radius: 0;
        border-top-right-radius: 30px; } }
  .icon__wrapper-gradient [class*="col"]:last-child > .icon__wrapper, .icon__wrapper-gradient [class*="col"]:last-child > .icon__wrapper > .after {
    border-top-right-radius: 30px;
    border-bottom-right-radius: 30px; }
    @media (max-width: 991px) {
      .icon__wrapper-gradient [class*="col"]:last-child > .icon__wrapper, .icon__wrapper-gradient [class*="col"]:last-child > .icon__wrapper > .after {
        border-top-right-radius: 0;
        border-bottom-left-radius: 30px; } }
  .icon__wrapper-gradient .icon__wrapper {
    position: relative;
    z-index: 1;
    overflow: visible; }
    .icon__wrapper-gradient .icon__wrapper > .after {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: linear-gradient(to top, var(--brand-primary), var(--secondary));
      opacity: 0.9;
      z-index: -2; }
    .icon__wrapper-gradient .icon__wrapper:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: #fff;
      border-radius: 30px;
      opacity: 0;
      z-index: -1;
      box-shadow: 0 6px 30px 0 rgba(0, 0, 0, 0.12);
      transition: all .4s ease; }
    .icon__wrapper-gradient .icon__wrapper svg path, .icon__wrapper-gradient .icon__wrapper svg line, .icon__wrapper-gradient .icon__wrapper svg polyline {
      stroke: #fff; }
    .icon__wrapper-gradient .icon__wrapper:hover, .icon__wrapper-gradient .icon__wrapper.current {
      z-index: 5; }
      .icon__wrapper-gradient .icon__wrapper:hover:before, .icon__wrapper-gradient .icon__wrapper.current:before {
        top: -20px;
        left: -20px;
        right: -20px;
        bottom: -20px;
        opacity: 1; }
      .icon__wrapper-gradient .icon__wrapper:hover svg path, .icon__wrapper-gradient .icon__wrapper:hover svg line, .icon__wrapper-gradient .icon__wrapper:hover svg polyline, .icon__wrapper-gradient .icon__wrapper.current svg path, .icon__wrapper-gradient .icon__wrapper.current svg line, .icon__wrapper-gradient .icon__wrapper.current svg polyline {
        stroke: var(--brand-primary); }
      .icon__wrapper-gradient .icon__wrapper:hover *, .icon__wrapper-gradient .icon__wrapper.current * {
        color: #333; }
      .icon__wrapper-gradient .icon__wrapper:hover p, .icon__wrapper-gradient .icon__wrapper.current p {
        color: #9F9F9F; }

.icon__wrapper-linear {
  box-shadow: 0 6px 30px 0 rgba(0, 0, 0, 0.12); }
  .icon__wrapper-linear .icon__wrapper {
    background: #F6F9FF;
    height: 100%; }
    .icon__wrapper-linear .icon__wrapper svg {
      width: 78px;
      height: 78px;
      margin-bottom: 15px; }
      .icon__wrapper-linear .icon__wrapper svg path, .icon__wrapper-linear .icon__wrapper svg line, .icon__wrapper-linear .icon__wrapper svg polyline {
        stroke: var(--brand-primary); }
    .icon__wrapper-linear .icon__wrapper p {
      color: #4e4e4e;
      margin-top: 21px; }
    .icon__wrapper-linear .icon__wrapper:hover, .icon__wrapper-linear .icon__wrapper.current {
      background: #FCFDFF; }

@media (min-width: 992px) {
  .icon__wrapper-overlay {
    box-shadow: 0 6px 30px 0 rgba(0, 0, 0, 0.12); } }

.icon__wrapper-overlay .icon__wrapper-body {
  border-top: 2px solid rgba(var(--brand-primary-rgb), 0.15);
  padding-top: 0;
  position: relative;
  z-index: 1; }
  .icon__wrapper-overlay .icon__wrapper-body > .after {
    position: absolute;
    top: calc(100% - 70px);
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(to top, var(--brand-primary), var(--brk-base-2));
    transition: all .4s ease;
    z-index: -1; }

.icon__wrapper-overlay [class*="col"] {
  box-shadow: -5px 0 10px 0 rgba(0, 0, 0, 0.05); }

.icon__wrapper-overlay .icon__wrapper {
  padding-top: 140px;
  margin: 20px 0; }
  @media (min-width: 992px) {
    .icon__wrapper-overlay .icon__wrapper {
      margin: 0; } }
  .icon__wrapper-overlay .icon__wrapper svg {
    margin-bottom: 0; }
    .icon__wrapper-overlay .icon__wrapper svg path, .icon__wrapper-overlay .icon__wrapper svg line, .icon__wrapper-overlay .icon__wrapper svg polyline {
      stroke: var(--brand-primary); }
  .icon__wrapper-overlay .icon__wrapper .svg-wrap {
    width: 170px;
    height: 170px;
    margin-top: -115px;
    margin-left: auto;
    margin-right: auto;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #fff;
    border: 2px solid rgba(var(--brand-primary-rgb), 0.15);
    position: relative;
    z-index: 1; }
    .icon__wrapper-overlay .icon__wrapper .svg-wrap > .before {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      border-radius: 50%;
      background: linear-gradient(to top, var(--brand-primary), var(--brk-base-5));
      z-index: -1;
      transition: all .4s ease;
      opacity: 0; }
    .icon__wrapper-overlay .icon__wrapper .svg-wrap:after {
      content: '';
      position: absolute;
      top: -12px;
      left: -12px;
      right: -12px;
      bottom: -12px;
      border: 10px solid #fff;
      border-radius: 50%; }
  .icon__wrapper-overlay .icon__wrapper p {
    color: #9F9F9F; }
  .icon__wrapper-overlay .icon__wrapper:hover *, .icon__wrapper-overlay .icon__wrapper.current * {
    color: #fff; }
  .icon__wrapper-overlay .icon__wrapper:hover .icon__wrapper-body, .icon__wrapper-overlay .icon__wrapper.current .icon__wrapper-body {
    border-color: var(--brand-primary); }
    .icon__wrapper-overlay .icon__wrapper:hover .icon__wrapper-body > .after, .icon__wrapper-overlay .icon__wrapper.current .icon__wrapper-body > .after {
      top: 0; }
  .icon__wrapper-overlay .icon__wrapper:hover svg path, .icon__wrapper-overlay .icon__wrapper:hover svg line, .icon__wrapper-overlay .icon__wrapper:hover svg polyline, .icon__wrapper-overlay .icon__wrapper.current svg path, .icon__wrapper-overlay .icon__wrapper.current svg line, .icon__wrapper-overlay .icon__wrapper.current svg polyline {
    stroke: #fff; }
  .icon__wrapper-overlay .icon__wrapper:hover .svg-wrap, .icon__wrapper-overlay .icon__wrapper.current .svg-wrap {
    border: none; }
    .icon__wrapper-overlay .icon__wrapper:hover .svg-wrap > .before, .icon__wrapper-overlay .icon__wrapper.current .svg-wrap > .before {
      opacity: 1; }
    .icon__wrapper-overlay .icon__wrapper:hover .svg-wrap:after, .icon__wrapper-overlay .icon__wrapper.current .svg-wrap:after {
      top: 0;
      left: 0;
      right: 0;
      bottom: 0; }
  .icon__wrapper-overlay .icon__wrapper:hover .btn-inside-out, .icon__wrapper-overlay .icon__wrapper.current .btn-inside-out {
    box-shadow: 0 0 0 2px rgba(255, 255, 255, 0.3); }
    .icon__wrapper-overlay .icon__wrapper:hover .btn-inside-out .before,
    .icon__wrapper-overlay .icon__wrapper:hover .btn-inside-out .after, .icon__wrapper-overlay .icon__wrapper.current .btn-inside-out .before,
    .icon__wrapper-overlay .icon__wrapper.current .btn-inside-out .after {
      background-color: var(--brk-base-6); }
    .icon__wrapper-overlay .icon__wrapper:hover .btn-inside-out:hover > span, .icon__wrapper-overlay .icon__wrapper.current .btn-inside-out:hover > span {
      color: #fff; }
    .icon__wrapper-overlay .icon__wrapper:hover .btn-inside-out:before, .icon__wrapper-overlay .icon__wrapper:hover .btn-inside-out:after, .icon__wrapper-overlay .icon__wrapper.current .btn-inside-out:before, .icon__wrapper-overlay .icon__wrapper.current .btn-inside-out:after {
      transition: all .4s ease;
      background: rgba(var(--brand-primary-rgb), 0.7); }

.icon__wrapper-arrow {
  box-shadow: 0 6px 30px 0 rgba(0, 0, 0, 0.12); }
  @media (min-width: 992px) {
    .icon__wrapper-arrow [class*="col"]:first-child > .icon__wrapper .tri-before {
      display: none; } }
  @media (max-width: 991px) {
    .icon__wrapper-arrow [class*="col"]:first-child > .icon__wrapper .tri-after {
      display: none; } }
  @media (min-width: 992px) {
    .icon__wrapper-arrow [class*="col"]:last-child > .icon__wrapper .tri-after {
      display: none; } }
  @media (max-width: 991px) {
    .icon__wrapper-arrow [class*="col"]:last-child > .icon__wrapper .tri-before {
      display: none; } }
  .icon__wrapper-arrow .icon__wrapper {
    position: relative;
    z-index: 1;
    padding-left: 20px;
    padding-right: 20px; }
    @media (min-width: 992px) {
      .icon__wrapper-arrow .icon__wrapper {
        padding-left: 40px;
        padding-right: 20px; } }
    .icon__wrapper-arrow .icon__wrapper > .after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: linear-gradient(to top, var(--brk-base-6), var(--brk-base-5));
      transition: all .4s ease;
      opacity: 0;
      z-index: -1; }
    .icon__wrapper-arrow .icon__wrapper .tri-before {
      opacity: 0;
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 20px;
      margin-top: 0;
      background: #fff;
      clip-path: polygon(50% 0, 0% 100%, 100% 100%);
      transition: all .3s ease;
      z-index: 2; }
      @media (min-width: 992px) {
        .icon__wrapper-arrow .icon__wrapper .tri-before {
          top: 0;
          bottom: 0;
          left: -1px;
          width: 22px;
          height: auto;
          clip-path: polygon(0% 0%, 4% 0, 100% 50%, 4% 100%, 0% 100%); } }
    .icon__wrapper-arrow .icon__wrapper .tri-after {
      opacity: 0;
      position: absolute;
      top: -20px;
      right: 0;
      width: 100%;
      height: 20px;
      margin-top: 0;
      background: var(--brk-base-5);
      transition: all .3s ease;
      clip-path: polygon(50% 0, 0% 100%, 100% 100%);
      z-index: 1; }
      @media (min-width: 992px) {
        .icon__wrapper-arrow .icon__wrapper .tri-after {
          top: 0;
          right: -21px;
          bottom: 0;
          width: 22px;
          height: auto;
          background: linear-gradient(to top, var(--brk-base-6), var(--brk-base-5));
          clip-path: polygon(0% 0%, 4% 0, 100% 50%, 4% 100%, 0% 100%); } }
    .icon__wrapper-arrow .icon__wrapper svg path, .icon__wrapper-arrow .icon__wrapper svg line, .icon__wrapper-arrow .icon__wrapper svg polyline {
      stroke: var(--brand-primary); }
    .icon__wrapper-arrow .icon__wrapper p {
      color: #9F9F9F; }
    .icon__wrapper-arrow .icon__wrapper:hover *, .icon__wrapper-arrow .icon__wrapper.current * {
      color: #fff; }
    .icon__wrapper-arrow .icon__wrapper:hover > .after, .icon__wrapper-arrow .icon__wrapper.current > .after {
      opacity: 1; }
    .icon__wrapper-arrow .icon__wrapper:hover .tri-before, .icon__wrapper-arrow .icon__wrapper:hover .tri-after, .icon__wrapper-arrow .icon__wrapper.current .tri-before, .icon__wrapper-arrow .icon__wrapper.current .tri-after {
      opacity: 1; }
    .icon__wrapper-arrow .icon__wrapper:hover svg path, .icon__wrapper-arrow .icon__wrapper:hover svg line, .icon__wrapper-arrow .icon__wrapper:hover svg polyline, .icon__wrapper-arrow .icon__wrapper.current svg path, .icon__wrapper-arrow .icon__wrapper.current svg line, .icon__wrapper-arrow .icon__wrapper.current svg polyline {
      stroke: #fff; }

@media (min-width: 992px) {
  .icon__wrapper-triangle {
    box-shadow: 0 6px 30px 0 rgba(0, 0, 0, 0.12); } }

.icon__wrapper-triangle [class*="col"]:last-child > .icon__wrapper .triangle {
  display: none; }

.icon__wrapper-triangle .icon__wrapper {
  position: relative;
  z-index: 1;
  box-shadow: 0 -4px 13px 0 rgba(0, 0, 0, 0.12); }
  .icon__wrapper-triangle .icon__wrapper:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: #fff;
    z-index: -4; }
  @media (min-width: 992px) {
    .icon__wrapper-triangle .icon__wrapper {
      box-shadow: 4px 0 13px 0 rgba(0, 0, 0, 0.12); } }
  .icon__wrapper-triangle .icon__wrapper .bg-before {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: -2;
    opacity: 0;
    transition: all .4s ease; }
  .icon__wrapper-triangle .icon__wrapper .grad-overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(to top, var(--brk-base-6), var(--brk-base-5));
    transition: all .4s ease;
    opacity: 0;
    z-index: -1; }
  .icon__wrapper-triangle .icon__wrapper .triangle {
    width: 30px;
    height: 30px;
    background: #fff;
    transform: translateX(50%) rotate(45deg);
    position: absolute;
    bottom: -15px;
    right: 50%;
    box-shadow: 0 0 13px 0 rgba(0, 0, 0, 0.12);
    z-index: -4; }
    @media (min-width: 992px) {
      .icon__wrapper-triangle .icon__wrapper .triangle {
        bottom: auto;
        top: 50%;
        right: -30px;
        transform: translateY(-50%) rotate(45deg);
        transform-origin: top left; } }
  .icon__wrapper-triangle .icon__wrapper svg {
    width: 125px;
    height: 125px; }
    @media (min-width: 992px) {
      .icon__wrapper-triangle .icon__wrapper svg {
        margin-bottom: 90px; } }
    .icon__wrapper-triangle .icon__wrapper svg path, .icon__wrapper-triangle .icon__wrapper svg line, .icon__wrapper-triangle .icon__wrapper svg polyline {
      stroke: var(--brand-primary); }
  .icon__wrapper-triangle .icon__wrapper p {
    color: #9F9F9F; }
  .icon__wrapper-triangle .icon__wrapper:hover, .icon__wrapper-triangle .icon__wrapper.current {
    box-shadow: none; }
    .icon__wrapper-triangle .icon__wrapper:hover *, .icon__wrapper-triangle .icon__wrapper.current * {
      color: #fff; }
    .icon__wrapper-triangle .icon__wrapper:hover svg path, .icon__wrapper-triangle .icon__wrapper:hover svg line, .icon__wrapper-triangle .icon__wrapper:hover svg polyline, .icon__wrapper-triangle .icon__wrapper.current svg path, .icon__wrapper-triangle .icon__wrapper.current svg line, .icon__wrapper-triangle .icon__wrapper.current svg polyline {
      stroke: #fff; }
    .icon__wrapper-triangle .icon__wrapper:hover .triangle, .icon__wrapper-triangle .icon__wrapper.current .triangle {
      background: var(--brk-base-6); }
      @media (min-width: 992px) {
        .icon__wrapper-triangle .icon__wrapper:hover .triangle, .icon__wrapper-triangle .icon__wrapper.current .triangle {
          background: var(--brk-base-2); } }
    .icon__wrapper-triangle .icon__wrapper:hover .bg-before, .icon__wrapper-triangle .icon__wrapper.current .bg-before {
      opacity: 1; }
    .icon__wrapper-triangle .icon__wrapper:hover .grad-overlay, .icon__wrapper-triangle .icon__wrapper.current .grad-overlay {
      opacity: 0.94; }
    .icon__wrapper-triangle .icon__wrapper:hover .btn-inside-out:hover > span, .icon__wrapper-triangle .icon__wrapper.current .btn-inside-out:hover > span {
      color: #333; }
    .icon__wrapper-triangle .icon__wrapper:hover .btn-inside-out:before, .icon__wrapper-triangle .icon__wrapper:hover .btn-inside-out:after, .icon__wrapper-triangle .icon__wrapper.current .btn-inside-out:before, .icon__wrapper-triangle .icon__wrapper.current .btn-inside-out:after {
      transition: all .4s ease;
      background: rgba(var(--brand-primary-rgb), 0.7); }

.icon__wrapper-strict {
  text-align: left; }
  .icon__wrapper-strict .icon__wrapper {
    position: relative;
    z-index: 1; }
    .icon__wrapper-strict .icon__wrapper .bg-before {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: -2;
      opacity: 0;
      transition: all .4s ease; }
    .icon__wrapper-strict .icon__wrapper .grad-overlay {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: linear-gradient(to top, var(--brk-base-6), var(--brk-base-5));
      transition: all .4s ease;
      opacity: 0;
      z-index: -1; }
    .icon__wrapper-strict .icon__wrapper .triangle {
      font-size: 16px;
      color: #fff;
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      bottom: -23px;
      transform: translateX(50%) rotate(90deg);
      right: 50%;
      width: 46px;
      height: 46px;
      border-radius: 50%;
      z-index: 3;
      transition: all .4s ease;
      pointer-events: none; }
      .icon__wrapper-strict .icon__wrapper .triangle-before {
        bottom: auto;
        top: -23px; }
        @media (min-width: 992px) {
          .icon__wrapper-strict .icon__wrapper .triangle-before {
            transform: translateY(50%);
            right: auto;
            left: -23px; } }
      @media (min-width: 992px) {
        .icon__wrapper-strict .icon__wrapper .triangle {
          bottom: auto;
          top: 50%;
          transform: translateY(-50%);
          right: -23px; } }
    .icon__wrapper-strict .icon__wrapper .link {
      display: inline-block;
      margin-top: 25px; }
      .icon__wrapper-strict .icon__wrapper .link .icon {
        vertical-align: baseline;
        padding-left: 10px; }
      .icon__wrapper-strict .icon__wrapper .link:hover .icon {
        transform: translateX(7px); }
    .icon__wrapper-strict .icon__wrapper:hover *, .icon__wrapper-strict .icon__wrapper.current * {
      color: #fff; }
    .icon__wrapper-strict .icon__wrapper:hover .bg-before, .icon__wrapper-strict .icon__wrapper.current .bg-before {
      opacity: 1; }
    .icon__wrapper-strict .icon__wrapper:hover .grad-overlay, .icon__wrapper-strict .icon__wrapper.current .grad-overlay {
      opacity: 0.94; }

.icon__wrapper .number {
  color: var(--brand-primary);
  margin-left: -10px; }
  .icon__wrapper .number + * {
    margin-top: 13px; }
